
/// 管理员用户路由
import Home from "../views/Home.vue";
const adminRoutes = [
     {
        path: "/home/index",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "控制台",
          icon: "HomeFilled",
          isShow: true,
        },
      },
      {
        path: "/table/useTable",
        name: "table",
        meta: {
          icon: "Management",
          title: "报价管理",
          isShow: true,
        },
        children: [
          {
            path: "/table/useTable/index",
            name: "useTable",
            component: () => import("@/views/table/useTable/index.vue"),
            meta: {
              icon: "Histogram",
              title: "设备价格查阅",
              // isShow: true,
              isHide: false,
            },
          },
          {
            path: "/table/index",
            name: "contact",
            component: () => import("@/views/table/index.vue"),
            meta: {
              icon: "TrendCharts",
              title: "需求填写",
              // isShow: true,
              isHide: false,

            }
          },
          {
            path: "/table/demandForm",
            name: "demandForm",
            component: () => import("@/views/table/demandForm.vue"),
            meta: {
              icon: "List",
              title: "表单列表",
              // isShow: true,
              isHide: false,
            }
          }
        ],
  },
       {
        path: "/official/index",
        name: "official",
        meta: {
          icon: "HelpFilled",
          title: "官网管理",
          isShow: true,
        },
        children: [
          {
            path: "/official/index",
            name: "official_form",
            component: () => import("@/views/official/index.vue"),
            meta: {
              icon: "List",
              title: "表单数据",
              // isShow: true,
              isHide: false,
            },
          }
        ],
      },
      {
        path: "/form/useForm",
        name: "form",
        meta: {
          icon: "Tools",
          title: "系统管理",
          isShow: true,
        },
        children: [
          {
            path: "/form/useForm/index",
            name: "useForm",
            component: () => import("@/views/form/useForm/index.vue"),
            meta: {
              icon: "UserFilled",
              title: "个人资料",
              // isShow: true,
              isHide: false,
            },
          },
          {
            path: "/form/useList/use.vue",
            name: "UserList",
            component: () => import("@/views/form/useList/use.vue"),
            meta: {
              icon: "Menu",
              title: "用户列表",
              // isShow: true,
              isHide: false,
            },
          },
          {
            path: "/form/products.vue",
            name: "product",
            component: () => import("@/views/form/products.vue"),
            meta: {
              icon: "Checked",
              title: "产品结构",
              // isShow: true,
              isHide: false,
            }
          },
          {
            path: "/form/exchangeRate.vue",
            name: "exchangeRate",
            component: () => import("@/views/form/exchangeRate.vue"),
            meta: {
              icon: "TrendCharts",
              title: "汇率",
              // isShow: true,
              isHide: false,
            }
          },
        ],
      },
]
export default adminRoutes