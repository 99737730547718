import { createApp } from 'vue';
import Vue from 'vue'
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/style/common.scss';
import * as Icons from "@element-plus/icons-vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn" //element-plus中文显示
import 'element-plus/theme-chalk/dark/css-vars.css'
import qs from 'qs';
import i18n from '@/lang/index';
import vPreviewImage from 'v-preview-image'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { setupDirectives } from '@/utils/directives';




const app = createApp(App);
// app.use(ElementPlus, {
//   locale: zhCn,
// })
app.provide('$qs', qs);
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key as keyof typeof Icons]);
})

// 注册全局自定义指令，如：v-permission权限指令
setupDirectives(app);

app
  .use(router)
  .use(ElementPlus)
  .use(i18n)
  .use(Viewer)
  .use(vPreviewImage)
  .mount('#app')
