
export default {
    login: {
        login: 'Sign up',
        quotation: 'Welcome to VERTEX Quotation System',
        user: 'User',
        password: 'Password',
        logins: 'Login',
        online: "online",
        offer: "Price System",
        system: "System Notification："
    },
    routers:{
        logOut:'Log Out',
        控制台: 'Dashboard',
        报价管理:'Quotation management',
        设备价格查阅:'Equipment price inquiry',
        需求填写:'Requirement form',
        表单列表: 'Demand form',
        官网管理:'Official website form',
        表单数据: 'Form data',
        系统管理:'System',
        个人资料:'Personal profile',
        用户列表:'User list',
        产品结构: 'Product structure',
        汇率: 'Exchange rate',

        SNI:'System Notification Information: ',
        SNImessages: 'messages in total',
        Serial:'Serial Number'
    },
    label: {
        snotifications:'System notifications',
        inotifications: 'Inquiry notifications',
    },
    table: {
        notification: 'Add Notification',
        Serial:'Serial Number',
        Title: 'Notification Title',
        content:'Notice content',
        time: 'Publication Time',
        action: 'Actions',
        detail: 'Details',
        edit: 'Edit',
        detele: 'Delete',
        progress: 'Inquiry Progress Information in the Last 30 Days:',
        entries: 'entries in total',
        save: 'Save',
        cancel: 'Cancel'
    },
    offer: {
        category: "Price Category",
        sou: "Search",
        add: "Add product",
        search: "Inquire",
        close: "Close",
        view:"View",
        code:"Material Code (Alias)",
        name: "Product Model",
        photo_num: "Material Drawing Number",
        version_num:"Material Version Number",
        power: "Power (kW)",
        price:"Price Category",
        price1: "Price (USD)",
        detail: "Detailed Explanation",
        data: "No Data Available",
        Level1:"Level 1 Permission Price",
        Level2:"Level 2 Permission Price",
        Level3: "Level 3 Permission Price",
        Level4: "Level 4 Permission Price",
        pro: "Product modification",
        zui: "Bottom Price",
        gao: "Peak Price",
        can: "Product parameter list",
        zan: "No parameters currently available",
        tuji: "Photos",
        ptype: "Product Type"
    },
    user: {
        username: "Username",
        nickname: "Nickname",
        Information: "Modify Information",
        resat: "Reset Password",
        User: "Add User",
        submit: "Submit",
        cancel: "Cancel",
        new: "New Password",
        old: "Old Password"
    },
    userList: {
        moble: "Phone Number",
        email: "Email",
        level: "Permission Level",
        time: "Creation Time",
        Subcategory: "Add Subcategory",
        Category: "Delete this Category",
        edit: "Edit this Category",
        add: "add",
        name:"Category name:",
        shu: "Data loading..."
    },
    official: {
        country_Code: "Country Code",
        country: "Country",
        desc: "Description",
        export: "Export"
    },
    form: {
        name: "Name",
        phone:"Contact Information",
        email:"Email",
        image: "Reference Picture",
        Information: "Requirement Information",
        dispose: "Dispose",
        can: "Main Parameter",
        clear:"Clear",
        time: "Time",
        chu: "Pending",
        li: "Processed",
        titime: "Submission Time",
        sttime: "Start Time",
        endtime: "End Time",
        rate: "Exchange Rate",
        agency: "Agency Offer",
        jsdi: "Complete Machine International Trade Quotation",
        currency: "RMB",
        ai: "Oops ~~ The page you are looking for is not on Earth...",
        fan: "Return to login page"
    }
}
